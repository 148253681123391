import React, {FC, PropsWithChildren, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSidebar} from "@entities/sidebar/lib/useSidebar";
import {Link} from "react-router-dom";
import {useRouteBuilder} from "@routes/hooks";
import {ROUTES} from "@routes/routes-list";
import {ChevronLeftIcon, EyeIcon, MenuIcon, NotificationIcon,} from "@features/icons";
import {NotificationsRepository} from "@src/repositories/NotificationsRepository";
import {toast} from "react-toastify";
import {getErrorMessage} from "@shared/lib/helpers";
import {useCompanyNotifications, useHasNewNotifications} from "@entities/notifications/lib/hooks";
import {useSoundPlayer} from "@features/sound-player/sound-player";
import {useParams} from "react-router";
import classNames from "classnames";
import {NOTIFICATION_AUTO_INTERVAL} from "@src/config";

export interface HeaderProps {
    showMenuButton?: boolean;
    showNotificationButton?: boolean;
    showBackButton?: boolean;
    showReadAllNotificationsButton?: boolean;
    rightContent?: React.ReactNode;
}

export const Header: FC<HeaderProps & PropsWithChildren> = ({
                                                                showMenuButton = true,
                                                                showBackButton,
                                                                showNotificationButton,
                                                                showReadAllNotificationsButton,
                                                                rightContent,
                                                                children,
                                                            }) => {
    const {t} = useTranslation();
    const {buildRoute} = useRouteBuilder();
    const {toggleSidebar} = useSidebar();
    const [loading, setLoading] = useState('')
    const {errorSound} = useSoundPlayer()
    const {accountId, companyId, companyAddressId} = useParams()
    const {mutate: updateNotifications, data: notifications} = useCompanyNotifications(showReadAllNotificationsButton ?? false)
    const {data: hasNew} = useHasNewNotifications()
    const {unresolvedSound} = useSoundPlayer()
    const timer = useRef<NodeJS.Timeout>()
    const acceptAllNotifications = async () => {
        try {
            setLoading('all')
            await NotificationsRepository.acceptAllNotifications({
                accountId: accountId as string,
                companyId: companyId as string,
                companyAddressId: companyAddressId
            })
            updateNotifications()
        } catch (e) {
            toast.error(t(getErrorMessage(e)))
            errorSound()
        } finally {
            setLoading('')
        }
    }

    const nasNotifications = hasNew?.hasNotifications ?? false

    const notify = () => {
        if(!hasNew?.hasNotifications) return
        unresolvedSound()
    }

    useEffect(() => {
        if(!hasNew?.hasNotifications) return
        if (timer.current) clearInterval(timer.current)
        timer.current = setInterval(notify, NOTIFICATION_AUTO_INTERVAL)
        return () => {
            if (timer.current) clearInterval(timer.current)
        }
    }, [hasNew])

    return (
        <header className="header">
            {showMenuButton && (
                <button
                    data-testid={"menu-btn"}
                    onClick={toggleSidebar}
                    className={"header-btn btn-info relative header-btn-left"}
                >
                    <MenuIcon className={""}/>
                    {
                        nasNotifications && <>
                        <span className="flex md:hidden badge badge-circle badge-info absolute top-2 right-2"></span>
                        <span
                          className="flex md:hidden badge ring-1 animate animate-ping badge-circle badge-info absolute top-2 right-2"></span>
                      </>
                    }

                </button>
            )}

            {showBackButton && (
                <Link
                    to={buildRoute(ROUTES.NOW_ORDERS)}
                    data-testid={"back-btn"}
                    className={"header-btn btn-info header-btn-left"}
                >
                    <ChevronLeftIcon className={""}/>
                </Link>
            )}
            <div className={"header-content"}>{children}</div>

            {showNotificationButton && (
                <div className={"hidden md:flex"}>
                    <Link
                        to={buildRoute(ROUTES.NOTIFICATIONS)}
                        data-testid={"notifications-btn"}
                        className={"header-btn btn-info header-btn-right"}
                    >
                        <NotificationIcon/>
                        {
                            nasNotifications && <>
                            <span className="badge badge-circle badge-info absolute top-4 right-4"></span>
                            <span
                              className="badge ring-1 animate animate-ping badge-circle badge-info absolute top-4 right-4"></span>
                          </>
                        }
                    </Link>
                </div>
            )}

            {showReadAllNotificationsButton && (
                <button
                    disabled={loading === 'all' || !notifications || notifications?.items?.length === 0}
                    onClick={acceptAllNotifications}
                    className={classNames("header-btn btn-info header-btn-right", {skeleton: !notifications})}
                    data-testid={"read-all-notifications-btn"}
                >
                    <EyeIcon/>
                    <span className={"hidden md:inline"}>
            {" "}
                        {t("read_all_notifications")}
          </span>
                </button>
            )}
            {rightContent}
        </header>
    );
};
